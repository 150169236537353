@import 'vendor/normalize', 'vendor/animate';
@import 'mixins';

/* ==== Main CSS ==== */
$color-primary: #6375E4;
$color-primary-dark: #101010;
$color-light: #fff;

// Breakpoints
$bp-xsmall: 479px;
$bp-small: 599px;
$bp-medium: 770px;
$bp-large: 979px;
$bp-xlarge: 1199px;

body  {
	color: $color-primary-dark;
	font-family: "brandon-grotesque",sans-serif;
}

a {
	border-bottom: 1px solid $color-primary-dark;
	color: $color-primary-dark;
	text-decoration: none;
	transition: all 500ms ease-in-out;
	pointer-events: visible;

	&:hover {
		border-bottom: 4px solid rgba(55, 54, 54, 1);
		color: rgba(55, 54, 54, 1);
	}
}

.self {
	display: inline-block;
  min-width: 320px;
	margin: 0;
	padding: 50px;
	z-index: 100;

	@media (max-width: 400px) {
		min-width: inherit;
		padding: 20px;
	}

	&__name {
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		color: $color-primary-dark;
		font-weight: bold;
		font-size: 52px;
		text-transform: uppercase;

		@media (max-width: 400px) {
			padding-top: 5px;
			padding-bottom: 5px;
			font-size: 32px;
		}
	}

	&__about {
		margin: 20px 0 0;
		color: $color-primary-dark;
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 1px;
		text-transform: uppercase;

		@media (max-width: 400px) {
			background-color: transparent;
		}
	}

	&__attributes {
		padding: 0;
		list-style: none;

		li {
			margin-top: 10px;
			padding-left: 26px;

			@media (max-width: 400px) {
				margin-top: 5px;
				margin-bottom: 5px;
				padding-top: 5px;
				padding-bottom: 5px;
			}

			svg {
				margin-left: -28px;
				height: 12px;
			}
		}
	}

	&__social {
  	margin-top: 50px;
  	margin-bottom: 0;
  	padding-top: 10px;
  	border-top: 1px solid rgba(197, 199, 208, 0.3);
  	color: rgba(197, 199, 208, .7);
  	font-size: 12px;
  	font-weight: bold;
  	text-transform: uppercase;
  	letter-spacing: 3px;

  	a  {
  		border-color: rgba(197, 199, 208, .8);
  		color: rgba(197, 199, 208, .8);
  		font-weight: normal;
  	}
	}
}

.section {
  &__intro {
    position: relative;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01), rgba(230, 230, 230, 0.17), rgba(255, 255, 255, 0.35), white);
    pointer-events: none;

    @include bp(min-width, $bp-medium + 1) {
      border-width: 20px;
    }

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../images/black-white-city.jpg') no-repeat;
      background-size: cover;
      opacity: .25;
      z-index: -1;
    }

    h1 {
      border-bottom-color: rgba(black, .75);
    }

    .tagline {
      margin-top: 10px;
      padding: 10px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: black;
      background-color: white;
      box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.08);

      @include bp(min-width, $bp-medium + 1) {
        font-size: inherit;
      }
    }
  }

  &__pager--next {
    position: absolute;
    bottom: -175px;
    left: -25px;
    padding-right: 24px;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    transform: rotate(270deg);
    border-bottom: 1px solid currentColor;
    opacity: .75;
  }

  &__about {
    position: relative;
    min-height: auto;
    background-color: rgba($color-primary-dark, .75);
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.33);
      pointer-events: none;
      opacity: .5;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 28px;
      text-transform: uppercase;
      letter-spacing: 5px;
      text-align: center;
      background: url('../images/black-white-city.jpg') no-repeat;
      background-attachment: fixed;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: cover;
      color: white;

      @include bp(min-width, $bp-medium + 1) {
        font-size: 38px;
      }

      li {
        display: inline;
				padding-left: 8px;
				padding-right: 8px;
        word-break: break-all;
      }
    }
  }

  &__projects {
    position: relative;
    padding: 50px 10px 50px 40px;
    background-color: white;

    @include bp(min-width, $bp-medium + 1) {
      padding-left: 75px;
      padding-right: 75px;
    }

    ul {
      clear: both; //clear the floating h2 title
      list-style: none;
      margin: 0;
      padding: 0;
    }

    > h2 {
      position: relative;
      display: inline-block;
      float: right;
      margin-bottom: 50px;
      margin-right: 15vw;
      padding: 20px 30px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: currentColor;
      background-color: transparent;
      border: 2px solid currentColor;
      z-index: 200;

      @include bp(max-width, $bp-medium) {
        margin-left: -10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .sites--current {
      margin-bottom: 150px;
    }

    .site {
      position: relative;
      margin-top: 50px;

      @include bp(min-width, $bp-medium + 1) {
        margin-top: 0;
      }

      @include hover {
        .site__date,
        .site__url {
          color: $color-primary-dark;
        }
      }

      &__content {
        position: relative;
        min-height: 145px;
        padding-bottom: 20px;
        padding-right: 20px;
        background-color: white;
        border: 2px solid currentColor;
        z-index: 2;
				transition: all .2s ease-in-out;

        @include hover {
          box-shadow: 0 0 0 5px white, 0 0 0 10px currentColor;
        }

				p {
					margin-left: 25px;

					span {
						padding-right: 5px;
						font-size: 12px;
						letter-spacing: 1px;
						text-transform: uppercase;
					}

					a {
						color: currentColor;
						border-bottom-color: currentColor;
					}
				}
      }

      &__title {
				display: inline-block;
				margin: 0;
				margin-left: 25px;
				padding: 15px;
				font-size: 16px;
				text-transform: uppercase;
				color: currentColor;
				border-left: 2px solid currentColor;
				border-bottom: 2px solid currentColor;
				box-shadow: -4px 5px 0px currentColor;
      }

      &__url {
        position: relative;
        font-size: 13px;
        font-weight: normal;
        color: #999;
        border-bottom: 1px solid currentColor;
				word-break: break-word;
				line-height: 1.33;

				@include bp(min-width, $bp-medium + 1) {
					margin-left: 10px;
				}

        a {
          transition: all .2 ease;

          @include hover {
            padding-right: 20px;
            border-bottom-width: 1px;
          }
        }
      }

      &__date {
        transform: rotate(270deg);
        position: absolute;
        top: 50px;
        left: -64px;
        font-size: 48px;
        color: $color-primary-dark;
        z-index: 1;

        &--current {
          left: -90px;
        }
      }

      &__tags {
				margin-top: 15px;
        padding-left: 43px;
        list-style: disc;

        li {
          line-height: 2;
        }
      }
    }
  }

  &__contact {
    background-color: $color-primary-dark;
    opacity: .75;
    color: $color-light;
    min-height: 250px;
    display: flex;
    justify-content: center;

    a {
      border-bottom: 0;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      list-style: none;
      padding-left: 0;

      li {
        padding: 125px 75px;
      }
    }
  }

  &__tools {
    margin: 20px;
    padding: 150px 50px;
    text-align: center;
    background-color: rgba($color-primary-dark, .95);
    color: white;

    @include bp(max-width, $bp-medium) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

		h2 {
			font-size: 22px;
			letter-spacing: 2px;
			text-transform: uppercase;
		}

    > ul {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      list-style: none;

      @include bp(max-width, $bp-medium) {
        flex-wrap: wrap;
        margin-top: 0;
        padding-left: 0;
      }

      a {
        color: currentColor;
        border-bottom-color: transparent;

        @include hover {
          border-color: currentColor;
        }
      }

      > li {
        width: 100%;
        margin-left: 65px;

        @include bp(max-width, $bp-medium) {
          margin-left: 0;
          margin-top: 50px;
        }

        @include bp(min-width, $bp-xsmall + 1) {
          width: 50%;
        }

        @include bp(min-width, $bp-medium + 1) {
          width: auto;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      li {
        padding-bottom: 10px;
        text-align: left;

        > span {
          padding-left: 50px;
          padding-bottom: 5px;
          font-size: 14px;
          font-weight: 300;
          text-align: left;
          text-transform: uppercase;
          border-bottom: 1px solid;
        }

        > ul {
          margin-top: 30px;
          padding-left: 0;
          font-weight: 300;
          text-align: left;
          list-style: none;
        }
      }
    }
  }
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style: none;
  padding-left: 0;

  li {
    padding: 150px 15px 0;

    @include bp(min-width, $bp-medium + 1) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  a {
    display: block;
    color: $color-primary-dark;
    border: 0;
    opacity: .9;

    @include hover {
      svg * {
        fill: white;
      }
    }
  }

  svg {
    width: 40px;
    height: 40px;

    * {
      fill: currentColor;
    }
  }
}

// Courtesy of HTML5 Boilerplate
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden {
  @include visuallyhidden;
}



@mixin hover {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

$mq-support: true !default;
$mq-fixed-value: 1024px !default;

@mixin bp($feature, $value) {
  // Set global device param
  $media: only screen;

  // Media queries supported
  @if $mq-support == true {

    @media #{$media} and ($feature: $value) {
      @content;
    }

    // Media queries not supported
  } @else {

    @if $feature == 'min-width' {
      @if $value <= $mq-fixed-value {
        @content;
      }
    } @else if $feature == 'max-width' {
      @if $value >= $mq-fixed-value {
        @content;
      }
    }

  }
}
